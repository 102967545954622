import inMemoryJWT from './inMemoryJWT';
import { fetchUtils } from 'react-admin';
import { stringify } from 'query-string';

/* sample from https://marmelab.com/react-admin/DataProviderWriting.html */

const apiUrl= 'https://tb.eyecyber.com:443/api/';

export default function tasksDataProvider (url) {
    const httpClient = (url, p_options) => {
        const options = {
            headers: new Headers({ Accept: 'application/json' }),
            ...p_options
        };
        const token = inMemoryJWT.getToken();
        if (token) {
            options.headers.set('x-access-token', `${token}`);
        }

        return fetchUtils.fetchJson(url, options);
    };

    return {
        getList: (resource, params) => {
            const { page, perPage } = params.pagination;
            const { field, order } = params.sort;
            const query = {
                sort: JSON.stringify([field, order]),
                range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
                filter: JSON.stringify(params.filter),
            };
            const url = `${apiUrl}/${resource}?${stringify(query)}`;

            return httpClient(url).then(function (response) {
                if (response.status < 200 || response.status >= 300) {
                    return Promise.reject(new Error("HTTP error" + response.status));
                }
                var ret_data = JSON.parse(response.body);


                /* mongoose has no id, but __id field, react-admin need id field exist */
                /* The response to 'getList' must be like { data: [...], total: 123 } or { data: [...], pageInfo: {...} }, but the received response has neither a 'total' nor a 'pageInfo' key. The dataProvider is probably wrong for 'getList' */
                var result = {};
                result.data = ret_data.result.map(resource => ({ ...resource, id: resource._id }));
                result.total = ret_data.result.length;
                return result;
            });
        },

        getOne: (resource, params) => {
            return httpClient(`${apiUrl}/${resource}/${params.id}`).then(function (response) {
                if (response.status < 200 || response.status >= 300) {
                    return Promise.reject(new Error("HTTP error" + response.status));
                }
                var ret_data = JSON.parse(response.body);

                var result = {};
                result.data = ret_data.result;
                result.data.id = ret_data.result._id;
                return result;
            });
        },

        getMany: (resource, params) => {
            const query = {
                filter: JSON.stringify({ ids: params.ids }),
            };
            const url = `${apiUrl}/${resource}?${stringify(query)}`;
//console.log("getList: " + url);
            return httpClient(url).then(function (response) {
                if (response.status < 200 || response.status >= 300) {
                    return Promise.reject(new Error("HTTP error" + response.status));
                }
                var ret_data = JSON.parse(response.body);
//console.log("ret_data: " + JSON.stringify(ret_data));


                /* mongoose has no id, but __id field, react-admin need id field exist */
                /* The response to 'getList' must be like { data: [...], total: 123 } or { data: [...], pageInfo: {...} }, but the received response has neither a 'total' nor a 'pageInfo' key. The dataProvider is probably wrong for 'getList' */
                var result = {};
                result.data = ret_data.result.map(resource => ({ ...resource, id: resource.device_id }));
                return result;
            });
        },

        getManyReference: (resource, params) => {
		const { page, perPage } = params.pagination;
		const { field, order } = params.sort;
		const query = {
		    sort: JSON.stringify([field, order]),
		    range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
		    filter: JSON.stringify({
			...params.filter,
			[params.target]: params.id,
		    }),
		};
		const url = `${apiUrl}/${resource}?${stringify(query)}`;

//console.log("getList: " + url);
		return httpClient(url).then(function (response) {
			if (response.status < 200 || response.status >= 300) {
			    return Promise.reject(new Error("HTTP error" + response.status));
			}
			var ret_data = JSON.parse(response.body);
//console.log("ret_data: " + JSON.stringify(ret_data));

			/* mongoose has no id, but __id field, react-admin need id field exist */
			/* The response to 'getList' must be like { data: [...], total: 123 } or { data: [...], pageInfo: {...} }, but the received response has neither a 'total' nor a 'pageInfo' key. The dataProvider is probably wrong for 'getList' */
			var result = {};
			result.data = ret_data.result.map(resource => ({ ...resource, id: resource.device_id }));
			result.total = ret_data.result.length;
			return result;
			});
        },

        create: (resource, params) => Promise.reject('create not implemented'),
        update: (resource, params) => Promise.reject('update not implemented'),
        updateMany: (resource, params) => Promise.reject('updateMany not implemented'),
        delete: (resource, params) => {
            httpClient(`${apiUrl}/${resource}/${params.id}`, {
                method: 'DELETE',
            }).then(function (response) {
                if (response.status < 200 || response.status >= 300) {
                    return Promise.reject(new Error("HTTP error" + response.status));
                }

                var ret_data = JSON.parse(response.body);
                var result = {};
                if (ret_data.result == "ok") {
                    result.data = params.id;
                }
                return result;
            });
        },

        deleteMany: (resource, params) => {
            const query = {
                filter: JSON.stringify({ ids: params.ids}),
            };
            console.log("deleteMany: " + `${apiUrl}/${resource}?${stringify(query)}`);
            return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
                method: 'DELETE',
                body: JSON.stringify(params.data),
            }).then(function (response) {
                if (response.status < 200 || response.status >= 300) {
                    return Promise.reject(new Error("HTTP error" + response.status));
                }

                var ret_data = JSON.parse(response.body);
                var result = {};
                if (ret_data.result == "ok") {
                    result.data = params.ids;
                }
                return result;
            });
        },
    };
};
