import React from 'react';
import PropTypes from 'prop-types';
import { useRecordContext } from "react-admin";

const OnlineStatus = function ({ source, record = {} }) {
    const one_record = useRecordContext();
    const last_update_ts = one_record.last_update_ts;
    if (!last_update_ts) {
        return (<span style={{color:"#DC143C"}}>Offline</span>);
    }

    let last_update_ts_epoch = new Date(last_update_ts);
    var now = new Date();
    //console.log("OnlineStatus: last_update_ts = " + last_update_ts);
    /* if last_update_ts is within last 2 mins, the unit is online */
    var online = (now.getTime() - last_update_ts_epoch.getTime()) < 120000;
    if (online) {
        return (<span style={{color:"#32CD32"}}>Online</span>);
    } else {
        return (<span style={{color:"#DC143C"}}>Offline</span>);
    }
}

OnlineStatus.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};

export default OnlineStatus;

