import PropTypes from 'prop-types';
import { useRecordContext } from "react-admin";
import { useState, useEffect } from 'react';
import { useDataProvider } from 'react-admin';
import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import axios from 'axios';
import inMemoryJWT from '../inMemoryJWT';
import { useNotify } from 'react-admin';

const deviceControlApiUrl = 'https://tb.eyecyber.com:443/api/device/';

const SelectFirmware = function ({ fw_type, record = {} }) {
    const notify = useNotify();
    const one_record = useRecordContext();
    const dataProvider = useDataProvider();
    const [fw, setFW] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const [selected_fw, set_selected_fw] = React.useState('');

    let model_filter = '';
    /* record.app_ver format 122-T5304V2-E23112207-C, the model is the second field */
    let cur_model = one_record.app_ver.split('-')[1];
    if (cur_model == "T5304V2") {
        if (fw_type == "fw") {
            model_filter = 'T5304V2';
        } else if (fw_type == "ai_fw") {
            model_filter = 'T5304V2-AI';
        }   
    } else if (cur_model == "DC082") {
        if (fw_type == "fw") {
            model_filter = 'DC082';
        } else if (fw_type == "ai_fw") {
            model_filter = 'DC082-AI';
        }
    }
    if (fw_type == "fw" && one_record.app_ver == "122-DC082-E24010901-C") {
        /* bad firmware version, not allow to upgrade */
        alert("Firmware version " + one_record.app_ver + " is not allowed to upgrade");
        return null;
    }
	//console.log(one_record);
	//console.log('cur_model ' + cur_model);
	//console.log('fw_type ' + fw_type);
	//console.log('model_filter ' + model_filter);
        
    const params = {
        pagination: { page: 1, perPage: 100 },
        sort: { field: 'id', order: 'ASC' },
        filter: {fw_type: fw_type }
    };
    if (model_filter) {
        params.filter.model = model_filter;
    }

    useEffect(() => {
        dataProvider.getList('firmwares', params)
                .then(({ data }) => {
                    setFW(data);
                    setLoading(false);
                })
                .catch(error => {
                    setError(error);
                    setLoading(false);
                })
        }, []);
    if (loading) return <span>Loading</span>;
    if (error) return <span>Error</span>;
    if (!fw) return null;

    const handleChange = (event: SelectChangeEvent) => {
        set_selected_fw(event.target.value);
        return;
    };

    const handleClick = (e) => {
        console.log('e is:', e);
        let btn = e.target;
        let btn_id = btn.id;
        let fw_type = btn_id.split('-')[1];
        let fw_select_e = btn.parentElement.children[0];
        let fw_name = selected_fw;

        if (!fw_type || !fw_select_e) {
            console.log('fw_type or fw_select_e is null');
            return;
        }

        console.log('fw_type is:', fw_type);
        console.log('fw_name is:', selected_fw);

        if (!selected_fw) {
            alert("No firmware is selected");
            return;
        };

        let ret = window.confirm("Are you sure to upgrade?");
        if (!ret) {
            return;
        }

        const token = inMemoryJWT.getToken();
        if (!token) {
            console.log("No token found");
            return;
        }

        let request_url = deviceControlApiUrl + "generic_fw_upgrade";

        var post_data = {};
        post_data.device_id = one_record.device_id;
        post_data.fw_type = fw_type;
        post_data.fw_name = fw_name;

        post_data.device_id = one_record.device_id;
        axios.post(request_url, post_data, {
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': `${token}`
            }
        }).then(function (response) {
            console.log(response);
            notify("Request to Upgrade FW Device : " + one_record.device_id, { type: 'success' });
        }).catch(function (error) {
            notify("Request to Upgrade FW Device : " + one_record.device_id + " failed :" + error, { type: 'error' });
        });
    };

    return (
        <Stack spacing={2} direction="row">
            <Select
            labelId="fw-select-select-label"
            id={"fwselect-" + fw_type}
            value={selected_fw}
            label="Firmware"
            onChange={handleChange}
            >
            {fw.map(one_fw => {
                return (
                    <MenuItem key={one_fw.fw_type + one_fw.fw_name} value={one_fw.fw_name}>{one_fw.fw_name}</MenuItem>
                );
            })}
            </Select>
            <Button id={"btn-" + fw_type} variant="contained" onClick={handleClick}>Upgrade</Button>
        </Stack>
    )
}

export default SelectFirmware;
