import { Datagrid, DateField, List, ReferenceField, TextField } from 'react-admin';

export const TaskList = () => (
    <List>
        <Datagrid>
            <ReferenceField source="device_id" reference="devices" />
            <TextField source="device_id" />
            <TextField source="imei" />
            <TextField source="task_type" />
            <TextField source="status" />
            <DateField label="Submit Time" source="submit_ts" showTime />
            <DateField label="Complete Time" source="complete_ts" showTime />
            <TextField label="Complete Status" source="result.verbose" />
        </Datagrid>
    </List>
);

