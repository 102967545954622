import inMemoryJWT from './inMemoryJWT';

export default {
  // called when the user attempts to log in
  login: ({ username, password }) => {
    const request = new Request('https://tb.eyecyber.com:443/api/login', {
      method: 'POST',
      body: JSON.stringify({ username, password }),
      headers: new Headers({ 'Content-Type': 'application/json' })
    });
    return fetch(request)
      .then((response) => {
          if (response.status < 200 || response.status >= 300) {
              throw new Error(response.statusText);
          }
          var data = response.json();
          if (data.error != null) {
              throw new Error(data.error);
          }
          return data;
      })
      .then((data) => inMemoryJWT.setToken(data.result.token));
  },
  // called when the user clicks on the logout button
  logout: () => {
    inMemoryJWT.ereaseToken();
    return Promise.resolve();
  },
  // called when the API returns an error
  checkError: (error) => {
    const status = error.status;
    if (status === 401 || status === 403) {
        inMemoryJWT.ereaseToken();
        return Promise.reject();
    }
    return Promise.resolve();
  },
  // called when the user navigates to a new location, to check for authentication
  checkAuth: () => {
    return inMemoryJWT.getToken() ? Promise.resolve() : Promise.reject();
  },
  // called when the user navigates to a new location, to check for permissions / roles
  getPermissions: () => {
    return inMemoryJWT.getToken() ? Promise.resolve() : Promise.reject();
  },
};
