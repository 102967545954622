import * as React from "react";
import { Admin, Resource, combineDataProviders, ListGuesser, EditGuesser} from "react-admin";
import buildRestProvider from 'ra-data-simple-rest';
import jsonServerProvider from "ra-data-json-server";
import { UserList } from "./UsersList";
import { DeviceList, DeviceEdit, DeviceShow } from "./DeviceList";
import { TaskList } from "./TaskList";
import { FirmwareList, FirmwareCreate } from "./FirmwareList";
//import PostIcon from "@material-ui/icons/Book";
import Dashboard from "./Dashboard";
import authProvider from "./authProvider";
import devicesDataProvider from "./devicesDataProvider";
import tasksDataProvider from "./tasksDataProvider";
import firmwaresDataProvider from "./firmwaresDataProvider";
//var devicesDataProvider = buildRestProvider('https://tb.eyecyber.com/api/');

const dataProvider = combineDataProviders((resource) => {
	switch (resource) {
		case 'devices':
			return devicesDataProvider("https://tb.eyecyber.com:443/api/");
		case 'tasks':
			return tasksDataProvider("https://tb.eyecyber.com:443/api/");
		case 'firmwares':
			return firmwaresDataProvider("https://tb.eyecyber.com:443/api/");
		default:
			throw new Error(`Unknown resource: ${resource}`);
	}
});

//const dataProvider = jsonServerProvider("https://jsonplaceholder.typicode.com");
const App = () => (
  <Admin
    dashboard={Dashboard}
    authProvider={authProvider}
    dataProvider={dataProvider}
  >
    <Resource
      name="devices"
      list={DeviceList}
      show={DeviceShow}
    />
    <Resource
      name="tasks"
      list={TaskList}
    />
    <Resource
      name="firmwares"
      list={FirmwareList}
      create={FirmwareCreate}
    />
  </Admin>
);

export default App;
