import React from 'react';
import PropTypes from 'prop-types';
import { useRecordContext } from "react-admin";

function second_to_time_str(diff_time) {
    var years = Math.floor(diff_time / 31536000);
    var months = Math.floor((diff_time % 31536000) / 2592000);
    var days = Math.floor((diff_time % 31536000) / 86400);
    var hours = Math.floor(((diff_time % 31536000) % 86400) / 3600);
    var minutes = Math.floor((((diff_time % 31536000) % 86400) % 3600) / 60);
    var seconds = Math.floor((((diff_time % 31536000) % 86400) % 3600) % 60);

    var time_str = "";
    if (years > 0) {
        time_str += years + " years ";
    }
    if (months > 0) {
        time_str += months + " months ";
    }
    if (days > 0) {
        time_str += days + " days ";
    }
    if (hours > 0) {
        time_str += hours + "h";
    }
    if (minutes > 0) {
        time_str += minutes + "m";
    }
    if (seconds > 0) {
        time_str += seconds + "s";
    }
    return time_str;
}

const LastSeen = function ({ source, record = {} }) {
    const one_record = useRecordContext();
    const last_update_ts = one_record.last_update_ts;
    if (!last_update_ts) {
        return (<span></span>);
    }

    let last_update_ts_epoch = new Date(last_update_ts);
    let now = new Date();
    let utcSeconds = last_update_ts_epoch /1000;
    let d = new Date(0); // The 0 there is the key, which sets the date to the epoch
    d.setUTCSeconds(utcSeconds);

    let diff_time = (now.getTime() - last_update_ts_epoch.getTime())/1000;

    var options = { hour12: false, year: 'numeric', month: 'long', day: 'numeric' };
    var time_str = d.toLocaleTimeString('en-US', options);
    let offset = second_to_time_str(diff_time);
    let offset_str = " (" + offset + " ago)";

    return (<div>
                <span>{time_str}</span>
                <br/>
                <span>{offset_str}</span>
            </div>);
}

LastSeen.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};

export default LastSeen;

