import * as React from "react";
import { useMediaQuery } from "@material-ui/core";
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  EditButton,
  Edit,
  Create,
  SimpleForm,
  SelectInput,
  TextInput,
  SimpleList,
  BooleanField,
  DateField,
  NumberField,
  BooleanInput,
  DateInput,
  NumberInput,
  ReferenceInput,
  Title,
  Labeled,
} from "react-admin";
import { FunctionField } from 'react-admin';

import { useNotify, useRecordContext, useGetOne, useRedirect, RecordContextProvider, SimpleShowLayout, Show, TabbedShowLayout  } from 'react-admin';
import { useGetManyReference  } from 'react-admin';
import { useWatch } from 'react-hook-form';

import { Card } from '@mui/material';
import { Button } from '@mui/material';
import { fetchUtils } from 'react-admin';
import inMemoryJWT from './inMemoryJWT';
import axios from 'axios';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import OnlineStatus  from './components/OnlineStatus';
import LastSeen  from './components/LastSeen';
import SelectFirmware  from './components/SelectFirmware';
import WifiMode  from './components/WifiMode';

const deviceControlApiUrl = 'https://tb.eyecyber.com:443/api/device/';

const deviceFilters = [
  <TextInput source="q" label="Search" alwaysOn />,
];

const IsOnline = props => {
};

export const DeviceList = (props) => (
    <List filters={deviceFilters} {...props}>
        <Datagrid rowClick="show">
            <TextField source="device_id" />
            <OnlineStatus label="Online" source="last_update_ts" />
            <LastSeen label="Last Seen" source="last_update_ts"/>
            <TextField label="LTE IP" source="lte_ip" />
            <TextField label="Version" source="app_ver" />
            <TextField label="APN" source="apn" />
            <TextField label="CPU_ID" source="cpu_id" />
            <TextField label="IMEI" source="imei" />
            <TextField label="SIM_ID" source="iccid" />
            <TextField label="Uptime" source="uptime_str"/>
            <NumberField label="GPS Lock" source="gps_lock" />
        </Datagrid>
    </List>
);

export const DeviceEdit = () => (
    <div>
    <TextField source="device_id" />
    <TextField source="lte_ip" />
    <TextField source="app_ver" />
    <TextField source="apn" />
    <TextField source="cpu_id" />
    <TextField source="imei" />
    <TextField source="iccid" />
    <DateField source="last_update_ts" />
    <TextField source="sn" />
    <NumberField source="uptime" />
    <NumberField source="gps_lock" />
    <Edit>
        <SimpleForm>
        </SimpleForm>
    </Edit>
    </div>
);

export const RebootButton = (props) => {
   const record = useRecordContext();
   const notify = useNotify();
   const handleClick = () => {
        const token = inMemoryJWT.getToken();
        if (!token) {
            console.log("No token found");
            return;
        }

        if (!window.confirm("Are you sure you want to reboot device : " + record.device_id + "?")) {
            return;
        }

        var post_data = {};
        post_data.device_id = record.device_id;
        axios.post(deviceControlApiUrl + 'reboot', post_data, {
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': `${token}`
            }
        }).then(function (response) {
            console.log(response);
            notify("Request to Reboot Device : " + record.device_id, { type: 'success' });
        }).catch(function (error) {
            notify("Request to Reboot Device : " + record.device_id + " failed :" + error, { type: 'error' });
        });
   };
   return <Button variant="contained" onClick={handleClick} {...props}>Reboot</Button>;
};

export const FactoryResetButton = (props) => {
   const record = useRecordContext();
   const notify = useNotify();
   const handleClick = () => {
        const token = inMemoryJWT.getToken();
        if (!token) {
            console.log("No token found");
            return;
        }

        if (!window.confirm("Are you sure you want to Factory Reset device : " + record.device_id + "?")) {
            return;
        }

        var post_data = {};
        post_data.device_id = record.device_id;
        axios.post(deviceControlApiUrl + 'factory_reset', post_data, {
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': `${token}`
            }
        }).then(function (response) {
            console.log(response);
            notify("Request to Factory Reset Device : " + record.device_id, { type: 'success' });
        }).catch(function (error) {
            notify("Request to Factory Reset Device : " + record.device_id + " failed :" + error, { type: 'error' });
        });
   };
   return <Button variant="contained" onClick={handleClick} {...props}>Factory Reset</Button>;
};

export const FormatSDCardButton = (props) => {
   const record = useRecordContext();
   const notify = useNotify();
   const handleClick = () => {
        const token = inMemoryJWT.getToken();
        if (!token) {
            console.log("No token found");
            return;
        }

        if (!window.confirm("Are you sure you want to format SD card of device : " + record.device_id + "?")) {
            return;
        }

        var post_data = {};
        post_data.device_id = record.device_id;
        axios.post(deviceControlApiUrl + 'format_sdcard', post_data, {
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': `${token}`
            }
        }).then(function (response) {
            console.log(response);
            notify("Request to FormatSDCard Device : " + record.device_id, { type: 'success' });
        }).catch(function (error) {
            notify("Request to FormatSDCard Device : " + record.device_id + " failed :" + error, { type: 'error' });
        });
   };
   return <Button variant="contained" onClick={handleClick} {...props}>Format SDCard</Button>;
};

function GrafanaUrl (props) {
    const record = useRecordContext();
    if (!record) return null;
    const handleClick = () => {
	let url = "https://tb.eyecyber.com:18300/d/2lBUwFPVk/dashcam-monitoring?orgId=1&refresh=1m&var-device_id=" + record.device_id + "&from=now-1h&to=now"
	window.open(url, '_blank').focus();
	return;
    };

    return <Button variant="contained" onClick={handleClick} {...props}>Open Grafana Chart</Button>;
}

function BasicTable() {
    const record = useRecordContext();
    if (!record) return null;
    return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Parameter</TableCell>
            <TableCell>Value</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
		<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
		      <TableCell component="th" scope="row"> Device ID </TableCell>
		      <TableCell>{record.device_id}</TableCell>
		</TableRow>
		<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
		      <TableCell component="th" scope="row"> Last Update </TableCell>
		      <TableCell><DateField source="last_update_ts" record={record} showTime /></TableCell>
		</TableRow>
		<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
		      <TableCell component="th" scope="row"> SN </TableCell>
		      <TableCell>{record.sn}</TableCell>
		</TableRow>
		<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
		      <TableCell component="th" scope="row"> CPU ID </TableCell>
		      <TableCell>{record.cpu_id}</TableCell>
		</TableRow>
		<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
		      <TableCell component="th" scope="row"> MAC </TableCell>
		      <TableCell>{record.mac}</TableCell>
		</TableRow>
		<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
		      <TableCell component="th" scope="row"> IMEI </TableCell>
		      <TableCell>{record.imei}</TableCell>
		</TableRow>
		<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
		      <TableCell component="th" scope="row"> Public IP </TableCell>
		      {record.pub_ip ? <TableCell>{record.pub_ip}</TableCell>
			          : <TableCell>"NA"</TableCell> }
		</TableRow>
		<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
		      <TableCell component="th" scope="row"> SIM Present </TableCell>
		      {record.sim ? <TableCell>Present</TableCell>
			          : <TableCell>Not Detected</TableCell> }
		</TableRow>
		<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
		      <TableCell component="th" scope="row"> SIM ID </TableCell>
		      <TableCell>{record.iccid}</TableCell>
		</TableRow>
		<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
		      <TableCell component="th" scope="row"> FW Version </TableCell>
		      <TableCell>{record.app_ver}</TableCell>
		</TableRow>
		<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
		      <TableCell component="th" scope="row"> AI Version </TableCell>
		      <TableCell>{record.ai_ver}</TableCell>
		</TableRow>
		<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
		      <TableCell component="th" scope="row"> MCU Version </TableCell>
		      <TableCell>{record.mcu_ver}</TableCell>
		</TableRow>
		<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
		      <TableCell component="th" scope="row"> Bluez Version </TableCell>
		      <TableCell>{record.bluez_ver}</TableCell>
		</TableRow>
		<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
		      <TableCell component="th" scope="row"> OBDII SN </TableCell>
		      <TableCell>{record.obdii_sn}</TableCell>
		</TableRow>
		<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
		      <TableCell component="th" scope="row"> OBDII HW </TableCell>
		      <TableCell>{record.obdii_hw}</TableCell>
		</TableRow>
		<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
		      <TableCell component="th" scope="row"> OBDII SW </TableCell>
		      <TableCell>{record.obdii_sw}</TableCell>
		</TableRow>
		<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
		      <TableCell component="th" scope="row"> OBDII VIN </TableCell>
		      <TableCell>{record.obdii_vin}</TableCell>
		</TableRow>
		<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
		      <TableCell component="th" scope="row"> APN </TableCell>
		      <TableCell>{record.apn}</TableCell>
		</TableRow>
		<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
		      <TableCell component="th" scope="row"> Internet Connected </TableCell>
		      {record.internet ? <TableCell>Connected</TableCell>
			          : <TableCell>Disconnected</TableCell> }
		</TableRow>
		<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
		      <TableCell component="th" scope="row"> Uptime </TableCell>
		      <TableCell>{record.uptime}</TableCell>
		</TableRow>
		<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
		      <TableCell component="th" scope="row"> CPU Temp </TableCell>
		      <TableCell>{record.cpu_temp}</TableCell>
		</TableRow>
		<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
		      <TableCell component="th" scope="row"> System voltage </TableCell>
		      <TableCell>{record.system_voltage}</TableCell>
		</TableRow>
		<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
		      <TableCell component="th" scope="row"> WiFi IP </TableCell>
		      <WifiMode label="Wifi Info" record={record}/>
		</TableRow>
		<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
		      <TableCell component="th" scope="row"> WiFi IP </TableCell>
		      <TableCell>{record.wifi_ip}</TableCell>
		</TableRow>
		<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
		      <TableCell component="th" scope="row"> WiFi SSID </TableCell>
		      <TableCell>{record.wifi_ssid}</TableCell>
		</TableRow>
		<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
		      <TableCell component="th" scope="row"> Modem Signal </TableCell>
		      <TableCell>{record.rssi}</TableCell>
		</TableRow>
		<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
		      <TableCell component="th" scope="row"> Modem Network Type </TableCell>
		      <TableCell>{record.network_tech}</TableCell>
		</TableRow>
		<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
		      <TableCell component="th" scope="row"> GPS Lock Status </TableCell>
		      {record.gps_lock ? <TableCell>Lock</TableCell>
			          : <TableCell>Not Lock</TableCell> }
		</TableRow>
		<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
		      <TableCell component="th" scope="row"> GPS Satellite </TableCell>
		      <TableCell>{record.sat}</TableCell>
		</TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function FirmwareUpgrade() {
	const record = useRecordContext();
	if (!record) return null;
	return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>FW</TableCell>
            <TableCell>Current Version</TableCell>
            <TableCell>Upgrade FW</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
		<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
		      <TableCell component="th" scope="row"> Main Firmware </TableCell>
		      <TableCell>{record.app_ver}</TableCell>
		      <TableCell><SelectFirmware fw_type="fw" /></TableCell>
		</TableRow>
        </TableBody>
        <TableBody>
		<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
		      <TableCell component="th" scope="row"> AI Firmware </TableCell>
		      <TableCell>{record.ai_ver}</TableCell>
		      <TableCell><SelectFirmware fw_type="ai_fw" /></TableCell>
		</TableRow>
        </TableBody>
        <TableBody>
		<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
		      <TableCell component="th" scope="row"> Modem Firmware </TableCell>
		      <TableCell>{record.modem_ver}</TableCell>
		      <TableCell><SelectFirmware fw_type="modem_fw" /></TableCell>
		</TableRow>
        </TableBody>
      </Table>
    </TableContainer>
	);
}

function DeviceTaskList()
{
	const record = useRecordContext();
	/* useGetManyReference will call to tasks.getManyReference({"target_filed":"id_field")} */
	const { data, isLoading, error } = useGetManyReference(
		'tasks',
		{ 
			target: 'device_id',
			id : record.device_id,
			pagination: { page: 1, perPage: 10 },
		}
		);
	if (isLoading) { return <p>Loading</p>; }
	if (error) { return <p>ERROR</p>; }
	return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Task Type</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Submit Time</TableCell>
            <TableCell>Complete Time</TableCell>
            <TableCell>Complete Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
		{data.map(task => (
			<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
			      <TableCell component="th" scope="row"> {task.task_type} </TableCell>
			      <TableCell>{task.status}</TableCell>
			      <TableCell>{task.submit_ts}</TableCell>
			      <TableCell>{task.complete_ts}</TableCell>
			      <TableCell>{task.result ? task.result.verbose : "-"}</TableCell>
			</TableRow>
		))}
        </TableBody>
      </Table>
    </TableContainer>
	       );
}

/* https://marmelab.com/react-admin/ShowTutorial.html */
export const DeviceShow = () => (
    <Show>
	    <TabbedShowLayout>
		    <TabbedShowLayout.Tab label="Summary">
{/*
			    <TextField label="Device ID" source="device_id" />
			    <TextField label="Last update" source="last_update_ts" />
			    <TextField label="SN" source="sn" />
			    <TextField label="CPU ID" source="cpu_id" />
			    <TextField label="MAC" source="mac" />
			    <TextField label="IMEI" source="imei" />
			    <TextField label="SIM Present" source="sim" />
			    <TextField label="SIM ID" source="iccid" />
			    <TextField label="FW Version" source="app_ver" />
			    <TextField label="AI Version" source="ai_ver" />
			    <TextField label="MCU Version" source="mcu_ver" />
			    <TextField label="APN" source="apn" />
			    <TextField label="Internet Status" source="internet" />
			    <TextField label="Uptime" source="uptime_str" />
			    <TextField label="CPU Temp" source="cpu_temp" />
			    <TextField label="System Voltage" source="system_voltage" />
			    <TextField label="WiFI IP" source="wifi_ip" />
			    <TextField label="Modem IP" source="lte_ip" />
			    <TextField label="Modem Signal" source="rssi" />
			    <TextField label="Modem Network" source="network_tech" />
			    <TextField label="GPS Lock" source="gps_lock" />
			    <TextField label="GPS Satellite" source="sat" />
*/}
			    <GrafanaUrl />
			    <BasicTable />
		    </TabbedShowLayout.Tab>
		    <TabbedShowLayout.Tab label="Control">
			    <RebootButton />
			    <FormatSDCardButton />
			    <FactoryResetButton />
		    </TabbedShowLayout.Tab>
		    <TabbedShowLayout.Tab label="Firmware Upgrade">
			    <FirmwareUpgrade/>
		    </TabbedShowLayout.Tab>
		    <TabbedShowLayout.Tab label="Tasks">
			    <DeviceTaskList/>
		    </TabbedShowLayout.Tab>
	    </TabbedShowLayout>
    </Show>
);

