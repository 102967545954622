import React from 'react';
import PropTypes from 'prop-types';
import { useRecordContext } from "react-admin";


const WifiMode = function ({ source, record = {} }) {
    const one_record = useRecordContext();
    const wifi_mode = one_record.wifi_mode;
    if (!wifi_mode) {
        return (<span></span>);
    }

    let mode = parseInt(wifi_mode);
    let mode_str = "Unknown";
    if (mode == 0) {
        mode_str = "Station";
    } else if (mode == 1) {
        mode_str = "AP";
    }

    let wifi_ssid = one_record.wifi_ssid;
    let wifi_password = one_record.wifi_password;
    let wifi_auth = one_record.wifi_auth;

    //AuthMode: 0--open, 1--shared, 2--WPA, 3--WPA-PSK
    let auth_str = "Unknown";
    if (parseInt(wifi_auth) == 0) {
	    auth_str = "Open";
    } else if (parseInt(wifi_auth) == 1) {
	    auth_str = "Shared";
    } else if (parseInt(wifi_auth) == 2) {
	    auth_str = "WPA";
    } else if (parseInt(wifi_auth) == 3) {
	    auth_str = "WPA-PSK";
    }

    return (<div>
                <span>Mode: {mode_str}</span>
		<br />
                <span>SSID: {wifi_ssid}</span>
		<br />
                <span>Auth: {auth_str}</span>
		<br />
                <span>Password: {wifi_password}</span>
            </div>);
}

WifiMode.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};

export default WifiMode;

