import inMemoryJWT from './inMemoryJWT';
import { fetchUtils } from 'react-admin';
import { stringify } from 'query-string';

/* sample from https://marmelab.com/react-admin/DataProviderWriting.html */

const apiUrl= 'https://tb.eyecyber.com:443/api/';

function uptime_str_convert(uptime)
{
	/* convert from seconds to days, hours, minutes, seconds */
	let days = Math.floor(uptime / (3600*24));
	uptime -= days*3600*24;
	let hrs = Math.floor(uptime / 3600);
	uptime -= hrs*3600;
	let mins = Math.floor(uptime / 60);
	uptime -= mins*60;
	let secs = Math.floor(uptime);
	
	let upTimeStr = "";
	if (days > 0 ) upTimeStr += days + "d ";
	if (hrs > 0 ) upTimeStr += hrs + "h ";
	if (mins > 0 ) upTimeStr += mins + "m ";
	if (secs > 0 ) upTimeStr += secs + "s ";
	return upTimeStr;
}


export default function devicesDataProvider (url) {
    const httpClient = (url) => {
        const options = {
            headers: new Headers({ Accept: 'application/json' }),
        };
        const token = inMemoryJWT.getToken();
//console.log("#1 set jwt token " + token);
        if (token) {
//console.log("#2 set jwt token " + token);
            options.headers.set('x-access-token', `${token}`);
        }

        return fetchUtils.fetchJson(url, options);
    };

    return {
        getList: (resource, params) => {
            const { page, perPage } = params.pagination;
            const { field, order } = params.sort;
            const query = {
                sort: JSON.stringify([field, order]),
                range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
                filter: JSON.stringify(params.filter),
            };
            const url = `${apiUrl}/${resource}?${stringify(query)}`;

//console.log("getList: " + url);
            return httpClient(url).then(function (response) {
                if (response.status < 200 || response.status >= 300) {
                    return Promise.reject(new Error("HTTP error" + response.status));
                }
                var ret_data = JSON.parse(response.body);
//console.log("ret_data: " + JSON.stringify(ret_data));


                /* mongoose has no id, but __id field, react-admin need id field exist */
                /* The response to 'getList' must be like { data: [...], total: 123 } or { data: [...], pageInfo: {...} }, but the received response has neither a 'total' nor a 'pageInfo' key. The dataProvider is probably wrong for 'getList' */
                var result = {};
                result.data = ret_data.result.map(resource => ({ ...resource, id: resource.device_id, uptime_str : uptime_str_convert(resource.uptime) }));
		if (ret_data.count != undefined)
			result.total = ret_data.count;
		else
			result.total = ret_data.result.length;
                return result;
            });
        },

        getOne: (resource, params) => {
            return httpClient(`${apiUrl}/${resource}/${params.id}`).then(function (response) {
                if (response.status < 200 || response.status >= 300) {
                    return Promise.reject(new Error("HTTP error" + response.status));
                }
                var ret_data = JSON.parse(response.body);

                var result = {};
                result.data = ret_data.result;
                result.data.id = ret_data.result.device_id;
		result.data.uptime_str = uptime_str_convert(ret_data.result.uptime);
                return result;
            });
        },

        getMany: (resource, params) => {
            const query = {
                filter: JSON.stringify({ ids: params.ids }),
            };
            const url = `${apiUrl}/${resource}?${stringify(query)}`;
//console.log("getList: " + url);
            return httpClient(url).then(function (response) {
                if (response.status < 200 || response.status >= 300) {
                    return Promise.reject(new Error("HTTP error" + response.status));
                }
                var ret_data = JSON.parse(response.body);
//console.log("ret_data: " + JSON.stringify(ret_data));


                /* mongoose has no id, but __id field, react-admin need id field exist */
                /* The response to 'getList' must be like { data: [...], total: 123 } or { data: [...], pageInfo: {...} }, but the received response has neither a 'total' nor a 'pageInfo' key. The dataProvider is probably wrong for 'getList' */
                var result = {};
                result.data = ret_data.result.map(resource => ({ ...resource, id: resource.device_id, uptime_str : uptime_str_convert(resource.uptime) }));
                return result;
            });
        },

        getManyReference: (resource, params) => {
		const { page, perPage } = params.pagination;
		const { field, order } = params.sort;
		const query = {
		    sort: JSON.stringify([field, order]),
		    range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
		    filter: JSON.stringify({
			...params.filter,
			[params.target]: params.id,
		    }),
		};
		const url = `${apiUrl}/${resource}?${stringify(query)}`;

//console.log("getList: " + url);
		return httpClient(url).then(function (response) {
			if (response.status < 200 || response.status >= 300) {
			    return Promise.reject(new Error("HTTP error" + response.status));
			}
			var ret_data = JSON.parse(response.body);
//console.log("ret_data: " + JSON.stringify(ret_data));

			/* mongoose has no id, but __id field, react-admin need id field exist */
			/* The response to 'getList' must be like { data: [...], total: 123 } or { data: [...], pageInfo: {...} }, but the received response has neither a 'total' nor a 'pageInfo' key. The dataProvider is probably wrong for 'getList' */
			var result = {};
			result.data = ret_data.result.map(resource => ({ ...resource, id: resource.device_id }));
			result.total = ret_data.result.length;
			return result;
			});
        },


        create: (resource, params) => Promise.reject('create not implemented'),
        update: (resource, params) => Promise.reject('update not implemented'),
        updateMany: (resource, params) => Promise.reject('updateMany not implemented'),
        delete: (resource, params) => Promise.reject('delete not implemented'),
        deleteMany: (resource, params) => Promise.reject('deleteMany not implemented'),
    };
};
